import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Button,
  ButtonGroup,
  Accordion,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./package.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faTv,
  faUtensils,
  faThermometer,
  faTint,
  faPlug,
  faCartShopping,
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function Package() {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("batteryAndSolar");
  const [exchangeRates, setExchangeRates] = useState({});
  const [country, setCountry] = useState("");

  useEffect(() => {
    fetchGeolocationAndRates();
  }, []);

  const fetchGeolocationAndRates = async () => {
    try {
      // Step 1: Fetch geolocation data and currency information
      const ipapiResponse = await fetch("https://ipapi.co/json/");
      if (!ipapiResponse.ok) {
        throw new Error("Failed to fetch geolocation data");
      }
      const ipapiData = await ipapiResponse.json();
      const currencyCode = ipapiData.currency;
      setCountry(ipapiData.currency);
      // Step 2: Fetch exchange rates based on detected currency
      const ratesResponse = await fetch(
        `https://api.exchangerate-api.com/v4/latest/${currencyCode}`
      );
      if (!ratesResponse.ok) {
        throw new Error("Failed to fetch exchange rates");
      }
      const ratesData = await ratesResponse.json();
      setExchangeRates(ratesData.rates);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const convertPrice = (price, currency) => {
    if (exchangeRates[currency]) {
      return (price * exchangeRates[currency]).toFixed(2);
    } else {
      return "N/A";
    }
  };

  const handleUpgradePlan = () => {
    navigate("/Registration");
  };
  const packages = {
    batteryAndSolar: [
      {
        title: "Shine",
        description:
          "Transform your dream into reality with a bespoke online store that's crafted to perfection. Any design, any feature—your vision brought to life by our expert designers. This is the ultimate ecommerce experience, tailored just for you!",
        price: "350",
        subtitle: "Unlimited items to showcase",
        hours: "Complimentary custom domain | Free premium hosting",
        componets: [
          "Unlimited customer reviews to build trust",
          "Collaborate with a designer to create your custom store",
          "Integrated shopping cart for seamless purchases",
        ],
        appliances: [
          "Unlimited product reviews to enhance credibility",
          "Fully custom ecommerce store design",
          "Your unique logo and store name to establish your brand",
        ],
      },
      {
        title: "Flash Plus",
        description:
          "Get your store up and running with ease. A user-friendly interface, perfectly designed for small to medium-sized businesses. Start selling today with a store that’s as intuitive as it is powerful.",
        price: "160",
        subtitle: "Upload up to 10 items",
        hours: "Complimentary custom domain | Free premium hosting",
        componets: [
          "Simple, no-fuss setup—perfect for beginners",
          "Start selling without the need for reviews",
          "Upload your items and begin trading today",
        ],
        appliances: [
          "No reviews required for simplicity",
          "Ready-to-launch ecommerce store",
          "Your store name prominently displayed",
        ],
      },
      {
        title: "Spark",
        description:
          "Ignite your business with a store that's both elegant and efficient. A clean design, combined with powerful search functionality, makes shopping a breeze for your customers.",
        price: "200",
        subtitle: "Showcase up to 30 items",
        hours: "Complimentary custom domain | Free premium hosting",
        componets: [
          "20 reviews per product to boost customer confidence",
          "Elegant, easy-to-navigate store layout",
          "Start selling immediately with an intuitive setup",
        ],
        appliances: [
          "20 product reviews to enhance buyer trust",
          "A professionally designed ecommerce store",
          "Your logo displayed proudly to represent your brand",
        ],
      },
      {
        title: "Glow",
        description:
          "Shine bright with a store that offers unlimited potential. With a sleek interface and unlimited product listings, your store will be the go-to destination for your customers.",
        price: "250",
        subtitle: "Unlimited product listings",
        hours: "Complimentary custom domain | Free premium hosting",
        componets: [
          "Unlimited reviews to showcase customer satisfaction",
          "Beautiful, user-friendly store design",
          "Built-in shopping cart for a seamless buying experience",
        ],
        appliances: [
          "Unlimited product reviews to build your brand's reputation",
          "A ready-made ecommerce store, polished to perfection",
          "Your store name prominently featured for instant recognition",
        ],
      },
    ],
  };
  

  const progressValues = [25, 50, 75, 100];

  const currencySymbols = {
    AED: "د.إ", // UAE Dirham
    AFN: "؋", // Afghan Afghani
    ALL: "L", // Albanian Lek
    AMD: "֏", // Armenian Dram
    ANG: "ƒ", // Netherlands Antillean Guilder
    AOA: "Kz", // Angolan Kwanza
    ARS: "$", // Argentine Peso
    AUD: "A$", // Australian Dollar
    AWG: "ƒ", // Aruban Florin
    AZN: "₼", // Azerbaijani Manat
    BAM: "KM", // Bosnia and Herzegovina Convertible Mark
    BBD: "Bds$", // Barbadian Dollar
    BDT: "৳", // Bangladeshi Taka
    BGN: "лв", // Bulgarian Lev
    BHD: ".د.ب", // Bahraini Dinar
    BIF: "FBu", // Burundian Franc
    BMD: "$", // Bermudian Dollar
    BND: "B$", // Brunei Dollar
    BOB: "Bs.", // Bolivian Boliviano
    BRL: "R$", // Brazilian Real
    BSD: "$", // Bahamian Dollar
    BTN: "Nu.", // Bhutanese Ngultrum
    BWP: "P", // Botswana Pula
    BYN: "Br", // Belarusian Ruble
    BZD: "$", // Belize Dollar
    CAD: "C$", // Canadian Dollar
    CDF: "FC", // Congolese Franc
    CHF: "CHF", // Swiss Franc
    CLP: "$", // Chilean Peso
    CNY: "¥", // Chinese Yuan Renminbi
    COP: "$", // Colombian Peso
    CRC: "₡", // Costa Rican Colón
    CUP: "$MN", // Cuban Peso
    CVE: "$", // Cape Verdean Escudo
    CZK: "Kč", // Czech Koruna
    DJF: "Fdj", // Djiboutian Franc
    DKK: "kr", // Danish Krone
    DOP: "RD$", // Dominican Peso
    DZD: "د.ج", // Algerian Dinar
    EGP: "£", // Egyptian Pound
    ERN: "Nfk", // Eritrean Nakfa
    ETB: "Br", // Ethiopian Birr
    EUR: "€", // Euro
    FJD: "FJ$", // Fijian Dollar
    FKP: "£", // Falkland Islands Pound
    FOK: "kr", // Faroese Króna
    GBP: "£", // British Pound
    GEL: "₾", // Georgian Lari
    GGP: "£", // Guernsey Pound
    GHS: "GH₵", // Ghanaian Cedi
    GIP: "£", // Gibraltar Pound
    GMD: "D", // Gambian Dalasi
    GNF: "FG", // Guinean Franc
    GTQ: "Q", // Guatemalan Quetzal
    GYD: "$", // Guyanese Dollar
    HKD: "HK$", // Hong Kong Dollar
    HNL: "L", // Honduran Lempira
    HRK: "kn", // Croatian Kuna
    HTG: "G", // Haitian Gourde
    HUF: "Ft", // Hungarian Forint
    IDR: "Rp", // Indonesian Rupiah
    ILS: "₪", // Israeli New Shekel
    IMP: "£", // Isle of Man Pound
    INR: "₹", // Indian Rupee
    IQD: "ع.د", // Iraqi Dinar
    IRR: "﷼", // Iranian Rial
    ISK: "kr", // Icelandic Króna
    JEP: "£", // Jersey Pound
    JMD: "J$", // Jamaican Dollar
    JOD: "د.ا", // Jordanian Dinar
    JPY: "¥", // Japanese Yen
    KES: "KSh", // Kenyan Shilling
    KGS: "лв", // Kyrgyzstani Som
    KHR: "៛", // Cambodian Riel
    KID: "$", // Kiribati Dollar
    KMF: "CF", // Comorian Franc
    KRW: "₩", // South Korean Won
    KWD: "د.ك", // Kuwaiti Dinar
    KYD: "$", // Cayman Islands Dollar
    KZT: "₸", // Kazakhstani Tenge
    LAK: "₭", // Lao Kip
    LBP: "ل.ل", // Lebanese Pound
    LKR: "Rs", // Sri Lankan Rupee
    LRD: "$", // Liberian Dollar
    LSL: "M", // Lesotho Loti
    LYD: "ل.د", // Libyan Dinar
    MAD: "د.م.", // Moroccan Dirham
    MDL: "L", // Moldovan Leu
    MGA: "Ar", // Malagasy Ariary
    MKD: "ден", // Macedonian Denar
    MMK: "K", // Myanmar Kyat
    MNT: "₮", // Mongolian Tögrög
    MOP: "P", // Macanese Pataca
    MRU: "UM", // Mauritanian Ouguiya
    MUR: "₨", // Mauritian Rupee
    MVR: "Rf", // Maldivian Rufiyaa
    MWK: "MK", // Malawian Kwacha
    MXN: "Mex$", // Mexican Peso
    MYR: "RM", // Malaysian Ringgit
    MZN: "MT", // Mozambican Metical
    NAD: "$", // Namibian Dollar
    NGN: "₦", // Nigerian Naira
    NIO: "C$", // Nicaraguan Córdoba
    NOK: "kr", // Norwegian Krone
    NPR: "₨", // Nepalese Rupee
    NZD: "NZ$", // New Zealand Dollar
    OMR: "ر.ع.", // Omani Rial
    PAB: "B/.", // Panamanian Balboa
    PEN: "S/", // Peruvian Sol
    PGK: "K", // Papua New Guinean Kina
    PHP: "₱", // Philippine Peso
    PKR: "₨", // Pakistani Rupee
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    QAR: "ر.ق", // Qatari Riyal
    RON: "lei", // Romanian Leu
    RSD: "din", // Serbian Dinar
    RUB: "₽", // Russian Ruble
    RWF: "FRw", // Rwandan Franc
    SAR: "﷼", // Saudi Riyal
    SBD: "SI$", // Solomon Islands Dollar
    SCR: "₨", // Seychellois Rupee
    SDG: "ج.س.", // Sudanese Pound
    SEK: "kr", // Swedish Krona
    SGD: "S$", // Singapore Dollar
    SHP: "£", // Saint Helena Pound
    SLL: "Le", // Sierra Leonean Leone
    SOS: "Sh", // Somali Shilling
    SRD: "$", // Surinamese Dollar
    SSP: "£", // South Sudanese Pound
    STN: "Db", // São Tomé and Príncipe Dobra
    SYP: "£S", // Syrian Pound
    SZL: "L", // Swazi Lilangeni
    THB: "฿", // Thai Baht
    TJS: "ЅМ", // Tajikistani Somoni
    TMT: "T", // Turkmenistani Manat
    TND: "د.ت", // Tunisian Dinar
    TOP: "T$", // Tongan Paʻanga
    TRY: "₺", // Turkish Lira
    TTD: "TT$", // Trinidad and Tobago Dollar
    TVD: "$", // Tuvaluan Dollar
    TWD: "NT$", // New Taiwan Dollar
    TZS: "TSh", // Tanzanian Shilling
    UAH: "₴", // Ukrainian Hryvnia
    UGX: "USh", // Ugandan Shilling
    USD: "$", // US Dollar
    UYU: "$U", // Uruguayan Peso
    UZS: "so'm", // Uzbekistani Som
    VES: "Bs.", // Venezuelan Bolívar Soberano
    VND: "₫", // Vietnamese Đồng
    VUV: "VT", // Vanuatu Vatu
    WST: "WS$", // Samoan Tala
    XAF: "FCFA", // Central African CFA Franc
    XCD: "$", // East Caribbean Dollar
    XOF: "CFA", // West African CFA Franc
    XPF: "₣", // CFP Franc
    YER: "﷼", // Yemeni Rial
    ZAR: "R", // South African Rand
    ZMW: "ZK", // Zambian Kwacha
    ZWL: "$", // Zimbabwean Dollar
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? packages[selectedType].length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === packages[selectedType].length - 1 ? 0 : prevIndex + 1
    );
  };
  return (
    <Container id="package-section">
      <header className="text-center my-5">
        <h1>Our Packages</h1>
        <p className="lead">Choose the best package that suits your needs</p>
      </header>

      <div className="d-block d-md-none package-carousel">
        <Carousel ref={carouselRef} activeIndex={currentIndex} onSelect={setCurrentIndex} interval={300000}  controls={false}>
          {packages[selectedType].map((pkg, index) => (
            <Carousel.Item key={index}>
              
              <Card bg="dark" text="white" className="mb-4">

                <div className="progress-circle-container">
                  <svg className="progress-circle" viewBox="0 0 36 36">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                         a 15.9155 15.9155 0 0 1 0 31.831
                         a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${progressValues[index]}, 100`}
                      d="M18 2.0845
                         a 15.9155 15.9155 0 0 1 0 31.831
                         a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                  <div className="icon-circle">
                    <FontAwesomeIcon icon={faCartShopping} />
                  </div>
                </div>
                <Card.Body>
                  <h1>{pkg.title}</h1>
                  <Card.Text>{pkg.subtitle}</Card.Text>
                  <div className="backup-hours text-center">
                    {/* <h1>Your website</h1> */}
                    <h2>{pkg.hours}</h2>
                  </div>
                  <br />
                  <Card.Text style={{ height: "120px" }}>
                    {pkg.description}
                  </Card.Text>
                  <hr></hr>
                  <Card.Text>
                    <strong className="text-uppercase">Amount </strong>
                    <br />
                    <h2 className="price-color">
                      {Object.keys(exchangeRates)
                        .filter((currency) => currency === country)
                        .map((currency) => (
                          <div key={currency}>
                            {currencySymbols[currency]
                              ? currencySymbols[currency]
                              : currency}{" "}
                            {convertPrice(pkg.price, currency)} p/m
                          </div>
                        ))}
                    </h2>
                  </Card.Text>
                  <hr></hr>

                  <div className="text-center">
                    <button
                      className="btn btn-primary rounded-pill mr-2"
                      onClick={handleUpgradePlan}
                    >
                      Get package
                    </button>
                  </div>

                  <hr></hr>

                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="bg-dark text-white ">
                        What you get
                      </Accordion.Header>
                      <Accordion.Body className="bg-dark text-white">
                        <ul className="appliance-list">
                          {pkg.appliances.map((appliance, idx) => (
                            <li key={idx}>
                              <span className="appliance-txt">
                                {" "}
                                {appliance}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <hr></hr>
                  <h3>Store components</h3>
                  {pkg.componets.map((components, idx) => (
                    <li key={idx}>
                      <span> {components}</span>
                    </li>
                  ))}
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))}
          <div className="carousel-controls">
        <button className="carousel-ctrl-prev" onClick={handlePrevious}>
          <FontAwesomeIcon icon={faChevronCircleLeft} size={52}/>
        </button>
        <button className="carousel-ctrl-next" onClick={handleNext}>
          <FontAwesomeIcon icon={faChevronCircleRight} size={52} />
        </button>
      </div>
        </Carousel>
        
        
      </div>

      

      <div className="d-none d-md-block">
        <Row>
          {packages[selectedType].map((pkg, index) => (
            <Col key={index} xs={12} sm={6} md={3} className="mb-4">
              <Card bg="dark" text="white">
                <div className="progress-circle-container">
                  <svg className="progress-circle" viewBox="0 0 36 36">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                         a 15.9155 15.9155 0 0 1 0 31.831
                         a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${progressValues[index]}, 100`}
                      d="M18 2.0845
                         a 15.9155 15.9155 0 0 1 0 31.831
                         a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                  <div className="icon-circle">
                    <FontAwesomeIcon icon={faCartShopping} />
                  </div>
                </div>
                <Card.Body>
                  <h1>{pkg.title}</h1>
                  <Card.Text>{pkg.subtitle}</Card.Text>
                  <div className="backup-hours text-center">
                    {/* <h3>Your website</h3> */}
                    <h4>{pkg.hours}</h4>
                  </div>
                  <br />
                  <Card.Text>{pkg.description}</Card.Text>
                  <hr></hr>

                  <Card.Text>
                    <strong className="text-uppercase">Amount</strong>{" "}
                    <br />
                    <h2 className="price-color">
                      {Object.keys(exchangeRates)
                        .filter((currency) => currency === country)
                        .map((currency) => (
                          <div key={currency}>
                            {currencySymbols[currency]
                              ? currencySymbols[currency]
                              : currency}{" "}
                            {convertPrice(pkg.price, currency)} p/m
                          </div>
                        ))}
                    </h2>
                  </Card.Text>
                  <hr></hr>

                  <div className="text-center">
                    <button
                      className="btn btn-primary rounded-pill w-100 mr-2"
                      onClick={handleUpgradePlan}
                    >
                      Get package
                    </button>
                  </div>

                  <hr></hr>
                  <Accordion bg="dark" text="white">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="bg-dark text-white">
                        <strong>What you get</strong>
                      </Accordion.Header>
                      <Accordion.Body className="bg-dark text-white">
                        <ul className="appliance-list">
                          {pkg.appliances.map((appliance, idx) => (
                            <li key={idx}>
                              <span className="appliance-txt">
                                {" "}
                                {appliance}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <hr></hr>
                  <h3>Store components</h3>
                  {pkg.componets.map((components, idx) => (
                    <li key={idx}>
                      <span> {components}</span>
                    </li>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default Package;
