import React, { useContext } from "react";
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Landing from "./Pages/Landing";
import Registration from "./Pages/Registration";
import StoreLayout from "./Pages/StoreLayout";
import { AuthContext } from "./AuthContext";
import Settings from "./Components/StoreAdmin/StoreSettings";
import NewUserSettings from "./Pages/NewUserSetUp";
import ForgotPassword from "./Pages/ForgotPwd";
import News from "./Components/News/news";
import Visualize from "./Pages/AudioAnimation";


function App() {
  const { currentUser } = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Registration" element={<Registration />} />
        <Route path="/SetUp" element={<Settings />} />
        <Route path="/News" element={<News />} />
        <Route path="/news/:id" element={<News />} />
        <Route path="/AudioAnim" element={<Visualize/>} />
        {/* <Route path="/StoreLayout" element={<StoreLayout />} /> */}
        {/* <Route path="/home" element={<Home />} />
        <Route path="/SetUpStore" element={<NewUserSettings/>} /> */}
        <Route
          path="/SetUpStore"
          element={
            currentUser ? <NewUserSettings /> : <Navigate to="/Registration" />
          }
        />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route
          path="/StoreLayout"
          element={currentUser ? <StoreLayout /> : <Navigate to="/Login" />}
        />
        <Route
          path="/home"
          element={currentUser ? <Home /> : <Navigate to="/Login" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
