import * as React from 'react';
import { useEffect } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { useAudioRecorder } from 'react-audio-voice-recorder';

export default function Visualize() {
  const recorder = useAudioRecorder();

  useEffect(() => {
    // Start recording as soon as the component mounts
    if (recorder.startRecording) {
      recorder.startRecording();
    }
  }, [recorder]);

  return (
    <div style={styles.container}>
      {recorder.mediaRecorder && (
        <LiveAudioVisualizer
          mediaRecorder={recorder.mediaRecorder}
          width="400%"
          height="100%"
        />
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: 'rgba(158, 171, 230, 0.25)',
  },
};
